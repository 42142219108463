/**
 * Global Prosemirror styles that are applied to each card's content
 */

// NB: Font sizes need to be expressed in "em" units, otherwise they will not scale
// relative to their container
import { keyframes } from '@chakra-ui/react'
import { SystemStyleObject } from '@chakra-ui/system'
import { last } from 'lodash'

import { isMobileDevice } from 'utils/deviceDetection'
import { DRAGOVER_CLASS } from 'utils/hooks/useDroppable'

import { EXPAND_CARD_TRANSITION_TIME } from '../extensions/Card/constants'
import { HANDLE_HOVERING_ATTR } from '../extensions/DragDrop/GlobalDragHandlePlugin'
import {
  expandableShadowAnimation,
  expandableShadowAnimationReset,
} from '../extensions/ExpandableNodes/expandableStyles'
import {
  BODY_LINE_HEIGHT,
  fontStyles,
  getFontSizeStyles,
} from '../extensions/Font/fontStyles'
import { mathStyles } from '../extensions/Math/mathStyles'
import { SmartLayoutStyles } from '../extensions/SmartLayout/SmartLayoutStyles'
import {
  highlightStyles,
  paddedTextBackground,
} from '../extensions/TextColor/highlightStyles'
import { textColorStyles } from '../extensions/TextColor/textColorStyles'
import { BLOCK_MARGIN, FONT_SIZES } from './constants'
import { listStyles } from './listStyles'

const placeholderStyles: SystemStyleObject = {
  content: 'attr(placeholder)',
  position: 'absolute',
  top: 0,
  opacity: 0.3,
  pointerEvents: 'none',
  left: 0,
  width: '100%',
  color: 'var(--heading-color)',
  lineClamp: 1,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  boxOrient: 'vertical',
  wordBreak: 'break-all',
  height: '100%',
  whiteSpace: 'nowrap',
}

const nestedCardTransition = keyframes`
  0% {
    align-items: flex-start;
    grid-template-rows: 0.2fr;
    overflow: hidden;
  }
  99% {
    align-items: flex-start;
    overflow: hidden;
  }
  100% {
    align-items: initial;
    grid-template-rows: 1fr;
    overflow: visible;
  }
`

export const CardWrapperStyles: SystemStyleObject = {
  '@media screen and (max-width: 30rem)': {
    // Necessary because Button does not yet support responsive sizes:
    // https://github.com/chakra-ui/chakra-ui/discussions/2276
    '.insert-card-button': {
      height: 6,
      width: 6,
      minW: 6,
      fontSize: 'xs',
    },
  },
}

export const CardContentStyles: SystemStyleObject = {
  height: '100%',
  outlineWidth: '0px',
  lineHeight: ['1.6 !important', BODY_LINE_HEIGHT],
  fontSize: 'var(--font-size)',

  // CSS variables used by components to render consistently
  '--box-border-color': 'var(--chakra-colors-blackAlpha-200)',

  '--grid-outline-color': 'var(--chakra-colors-gray-200)',
  '--grid-border': '1px',
  '--grid-padding': `1.22em`,

  '&.is-dark': {
    '--grid-outline-color': 'var(--chakra-colors-gray-700)',
    '--box-border-color': 'var(--chakra-colors-whiteAlpha-400)',

    '.block-blockquote': {
      blockquote: {
        borderLeftColor: 'var(--accent-color)',
      },
    },
    ...highlightStyles.dark,
    ...textColorStyles.dark,
  },

  '.block-card': {
    '&.expandable-node': {
      '&-selected .expandable-selected': {
        animation: `${expandableShadowAnimation} 3000ms ease-in-out forwards`,
      },
      '&-reset .expandable-selected': {
        animation: `${expandableShadowAnimationReset}`,
      },
    },
  },

  // Class added to every block within a card via the BlockClass extension
  '.block': {
    my: BLOCK_MARGIN,
    fontFamily: 'var(--body-font)',
    fontWeight: 'var(--body-font-weight)',
    color: 'var(--body-color)',
  },

  // Display grid used to support animating the height of nested cards
  // See https://css-tricks.com/css-grid-can-do-auto-height-transitions/
  '&.is-nested': {
    display: 'grid',
    animation: `${nestedCardTransition} ${EXPAND_CARD_TRANSITION_TIME}ms ease-out forwards`,
  },

  // In full width cards, let full width blocks spill out of the content column
  '&.is-full-width': {
    '.block.is-full-width': {
      width: `var(--full-width)`,
      position: 'relative',
      // Center it
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },

  '&.is-borderless': {
    // Adjust accent image display
    '.node-cardAccentLayoutItem': {
      '> [data-node-view-wrapper]': {
        display: 'flex',
      },
      '.card-layout-cell-bg:not([data-card-layout="behind"], [data-card-layout="top"])':
        {
          margin: '1em',
          borderRadius: 'var(--box-border-radius)',
          height: 'auto',
        },
      '.card-layout-cell-bg:is([data-card-layout="behind"], [data-card-layout="top"])':
        {
          width: 'var(--editor-width)',
          position: 'absolute',
          transform: 'translateX(-50%)',
          left: '50%',
        },
    },
  },

  // Remove margin from the first block at the top level of the card, or the card layout item
  '[data-node-view-content-inner="cardLayoutItem"], [data-node-view-content-inner="card"]':
    {
      '> .first-block': {
        mt: '0em',
      },
      '> .last-block': {
        mb: '0em',
      },
    },

  'a.link': {
    color: 'var(--link-color)',
    cursor: 'pointer',
    fontWeight: 'bold',
    textDecoration: 'underline',
    '&[href=""]': {
      textDecorationStyle: 'dashed',
    },
    '&:hover': {
      color: 'var(--link-color-hover)',
    },
    '&:active': {
      color: 'var(--link-color-hover)',
    },
  },

  '.block-toggle': {
    '&.expandable-node': {
      '&-selected .toggle': {
        animation: `${expandableShadowAnimation} 3000ms ease-in-out forwards`,
      },
      '&-reset .toggle': {
        animation: `${expandableShadowAnimationReset}`,
      },
    },

    '.node-toggleSummary': {
      '&.is-empty': {
        '[placeholder]': {
          _before: placeholderStyles,
        },
      },
    },

    '[data-open="false"]': {
      '[data-node-view-content-inner="toggle"]': {
        '> :not(:first-child)': {
          display: 'none',
        },
      },
    },
  },

  '.block-heading': {
    my: ['1em', '1.5em'],
    '.heading': {
      position: 'relative',
    },

    '.heading[level="1"]': getFontSizeStyles('h1'),
    '.heading[level="2"]': getFontSizeStyles('h2'),
    '.heading[level="3"]': getFontSizeStyles('h3'),
    '.heading[level="4"]': getFontSizeStyles('h4'),

    '&.is-focused-inside.is-empty': {
      '.heading': {
        '&[placeholder]::before': placeholderStyles,
      },
    },
  },

  '.block-title': {
    my: '1.5em',

    '&.is-focused-inside.is-empty': {
      '.title': {
        '&[placeholder]::before': placeholderStyles,
      },
    },

    '.title': {
      position: 'relative',
      ...getFontSizeStyles('title'),
    },
  },

  '.block-paragraph': getFontSizeStyles('default'),

  ...fontStyles,

  ...highlightStyles.default,
  ...textColorStyles.default,

  ...listStyles,

  ...mathStyles,

  '.block-codeBlock': {
    pre: {
      fontFamily: 'mono',
      fontSize: FONT_SIZES.codeBlock,
      '@media print': {
        fontSize: last(FONT_SIZES.codeBlock),
      },
      color: 'var(--body-color)',
      background: 'var(--accent-color-background-muted)',
      borderRadius: 'var(--box-border-radius)',
      overflow: 'hidden',
      code: {
        display: 'block',
        padding: '.75rem 1rem',
      },
    },
  },
  code: {
    backgroundColor: 'var(--accent-color-background-muted)',
    color: 'var(--body-color)',
    ...paddedTextBackground,
  },

  '.block-blockquote': {
    blockquote: {
      pl: '1.5em',
    },
  },

  '.block-embed, .block-video': {
    '&.expandable-node': {
      '&-selected': {
        '.embed-preview, .embed-viewer, .video-player': {
          animation: `${expandableShadowAnimation} 3000ms ease-in-out forwards`,
        },
      },
      '&-reset': {
        '.embed-preview, .embed-viewer, .video-player': {
          animation: `${expandableShadowAnimationReset}`,
        },
      },
    },
  },

  '.block-image': {
    '&.expandable-node': {
      '&-selected': {
        '[data-selection-ring]': {
          animation: `${expandableShadowAnimation} 3000ms ease-in-out forwards`,
        },
      },
      '&-reset ': {
        '[data-selection-ring]': {
          animation: `${expandableShadowAnimationReset}`,
        },
      },
    },
  },

  '.block-contributors': {
    '.contributors-list': {
      fontSize: '1.25em',
      fontWeight: 'bold',
    },
  },

  '.mention': {
    bgColor: 'var(--accent-color-100)',
  },

  '.block-gallery': {
    // Pad out so images are flush with text and the border is as wide as columns
    // And fudge the vertical padding so galleries don't overlap each other but also
    // don't go too far from text
    mx: 'calc(-1 * var(--grid-padding))',
    my: 'calc(-0.25 * var(--grid-padding))',
    '.gallery': {
      px: 'var(--grid-padding)',
      py: 'calc(0.75 * var(--grid-padding))',
      border: '1px solid',
      borderColor: 'transparent',
      borderRadius: 'var(--box-border-radius)',

      '.add-image': {
        mb: 'calc(0.75 * var(--grid-padding))',
      },

      [`&.${DRAGOVER_CLASS}`]: {
        border: '1px dashed var(--chakra-colors-trueblue-300) !important',
        backgroundColor: 'var(--chakra-colors-trueblue-50) !important',
        // Our dragover logic doesn't work with children, so make sure
        // the placeholder won't take clicks while dragging
        '.gallery-placeholder': {
          pointerEvents: 'none',
        },
      },
    },

    '&.is-focused, &.is-empty': {
      '.gallery': {
        backgroundColor: 'blackAlpha.50',
      },
    },

    [`&.is-focused, &:hover, &[${HANDLE_HOVERING_ATTR}]`]: {
      '.gallery': {
        borderColor: 'var(--grid-outline-color)',
      },
      '.add-image': {
        opacity: 1,
      },
    },

    '&.expandable-node': {
      '&-selected .gallery': {
        animation: `${expandableShadowAnimation} 3000ms ease-in-out forwards`,
      },
      '&-reset .gallery': {
        animation: `${expandableShadowAnimationReset}`,
      },
    },
  },

  ...SmartLayoutStyles,

  '.block-gridLayout': {
    // Offsets the padding inside the cells so text stays aligned
    mx: 'calc(-1 * var(--grid-padding))',
    '.layout-content': {
      display: 'contents',
      '> div': {
        display: 'contents',
      },
    },

    '.grid-cell': {
      position: 'relative',
      height: '100%',
      paddingX: 'var(--grid-padding)',
      display: 'flex',
      flexDirection: 'column',
      outlineWidth: '1px',
      outlineStyle: 'none', // Hidden by default
      outlineColor: 'var(--grid-outline-color)',

      '.block-gallery': {
        my: '0',
      },
    },

    [`&.is-focused, &:hover, &[${HANDLE_HOVERING_ATTR}]`]: {
      '.is-editable': {
        '.grid-cell': {
          outlineStyle: 'solid',
        },
      },

      '.add-column': {
        opacity: 1,
      },
    },
  },

  '.node-cardLayoutItem, .node-cardAccentLayoutItem': {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    // this is a fix to prevent grid items from expanding in width
    // from: https://stackoverflow.com/questions/43311943/prevent-content-from-expanding-grid-items
    minWidth: 0,
    minHeight: 0,

    '> [data-node-view-wrapper]': {
      position: 'relative',
      height: '100%',
      width: '100%',
    },
  },
  '.block-table': {
    table: {
      '[data-node-view-content-inner="table"]': {
        // Table content wrapper
        display: 'contents',

        '[data-node-view-content-inner="tableRow"]': {
          // TableRow content wrapper
          display: 'contents',
        },
        td: {
          verticalAlign: 'top',

          // dont show heading placeholder text in tables, will overflow too easily
          '.block-heading [placeholder]::before': {
            display: 'none',
          },
        },
      },
    },
    td: {
      minWidth: '3em',
      paddingX: '1em',
      position: 'relative',
      '.block': {
        my: '0.75em',
        // DO NOT REMOVE THIS. This makes it so that any block child
        // of a table cell can't exceed 75% of the viewport width.
        maxWidth: isMobileDevice() ? '75vw' : undefined,
      },
    },
    // Added by prosemirror-tables https://github.com/ProseMirror/prosemirror-tables/blob/6b16ed3cf306886f2c169aebbe60701e1ac2deac/src/cellselection.js
    // Based on https://www.tiptap.dev/examples/tables/
    /* Give selected cells a blue overlay */
    '.selectedCell:after': {
      zIndex: 2,
      position: 'absolute',
      content: '""',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: 'trueblue.100',
      pointerEvents: 'none',
      opacity: 0.4,
    },
    '&.is-focused, &:hover': {
      'td:not(:first-of-type)': {
        borderLeft: '1px solid var(--grid-outline-color)',
      },
    },
  },
  '.tippy-arrow': {
    borderLeftColor: 'red',
  },

  '.footnote-label': {
    textDecoration: 'underline',
    textDecorationColor: 'var(--link-color)',
  },

  '.footnote': {
    color: 'var(--link-color)',
  },

  '.node-emoji': {
    // Ensures themed text blocks (like heading) show emojis properly
    color: 'initial',
  },
}
