export const mathStyles = {
  'math-display': {
    fontSize: '1.125em',
    '.katex': {
      whiteSpace: 'normal',
    },
  },
  'math-inline': {
    // The editing state
    '&.ProseMirror-selectednode': {
      marginY: '-0.25em',
    },
  },
  'math-display, math-inline': {
    // The editing state
    '&.ProseMirror-selectednode': {
      boxShadow: 'outline',
      borderRadius: 'var(--box-border-radius)',
      backgroundColor: 'gray.100',
      padding: '.25em',
      marginX: '3px', // Focus ring width
      '.math-src': {
        color: 'gray.700',
      },
    },
  },
}
