import { keyframes } from '@chakra-ui/react'

const boxShadowReset = '0 0 0 0 rgba(0, 0, 0, 0.0)'

export const expandableShadowAnimation = keyframes`
  0% {
    box-shadow: ${boxShadowReset};
  }
  7%,
  60% {
    box-shadow: var(--link-box-shadow);
  }
  100% {
    box-shadow: ${boxShadowReset};
  }
`

export const expandableShadowAnimationReset = keyframes`
  0% 
  100% {
    box-shadow: ${boxShadowReset};
  }
`
